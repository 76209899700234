import { type VariantProps, cva } from "class-variance-authority";
import React, { type JSX } from "react";
import { cn } from "utils/utils";

function getHeaderAnchor(text: string) {
  const formattedText = text
    .trim()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .replace(/\*\*(.*?)\*\*/g, "$1") // Supprimer le gras
    .replace(/\[(.*?)\]\(.*?\)/g, "$1") // Supprimer les liens markdown
    .replace(/`(.*?)`/g, "$1") // Supprimer le code inline
    .replace(/[^\w\s-]/g, "") // Supprimer les caractères spéciaux
    .replace(/\s+/g, "-") // Remplacer les espaces par des tirets
    .replace(/^-+|-+$/g, "");

  return formattedText;
}

export const headlineVariants = cva("font-medium", {
  variants: {
    variant: {
      primary: "text-primary",
      secondary: "text-secondary",
      secondaryDarker: "text-secondaryDarker",
      white: "text-white",
    },
    size: {
      h1: "text-[2.5rem] lg:text-[3.125rem] leading-[130%]",
      h2: "text-[2rem] lg:text-[2.5rem] leading-[48px]",
      h3: "text-[1.75rem] lg:text-[2rem] leading-[130%]",
      h4: "text-2xl lg:text-[1.75rem] leading-[130%]",
      h5: "text-xl lg:text-[1.5rem] leading-[130%]",
      h6: "text-lg lg:text-[1.375rem] leading-[130%]",
    },
    // fontWeight: {
    //     default: "font-bold",
    //     semibold: "font-semibold",
    // },

    // underline: {
    //     none: "",
    //     underlined:
    //         "decoration-current underline underline-offset-2 decoration-1",
    // },
    // marginBottom: {
    //     none: "mb-0",
    //     default: "mb-4",
    // },
  },
});

export interface HeadlineProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof headlineVariants> {
  as?: keyof Pick<
    JSX.IntrinsicElements,
    "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span" | "div"
  >;
  enableAnchor?: boolean;
}

const Headline = React.forwardRef<HTMLHeadingElement, HeadlineProps>(
  ({ className, variant, size = "h1", as, color, enableAnchor = false, ...props }, ref) => {
    if (!as) {
      as = size || "h1";
    }
    const Comp = as;
    
    return (
      <Comp
        id={
          enableAnchor && typeof props.children === "string"
            ? getHeaderAnchor(props.children as string)
            : undefined
        }
        className={cn(headlineVariants({ variant, size }), className)}
        ref={ref}
        {...props}
      />
    );
  },
);
Headline.displayName = "Headline";
export { Headline };
